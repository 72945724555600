import {Component} from "react";
import {SlideShow} from "../slideshow/SlideShow";
import {DetailBid} from "../detail_bid/DetailBid";
import {CategoriesBanner} from "../CategoriesBanner/CategoriesBanner";
import {Contact} from "../Contact/Contact";

export class Home extends Component {
    render() {
        return <>
            <SlideShow/>
            <DetailBid/>
            <CategoriesBanner/>
            <Contact/>
        </>;
    }
}