import React, {Component} from "react";
import {newsData, newsIDList, currentBid} from "../news/NewsData";
import {Link} from "react-router-dom";
import {domain} from "../../App";

export class DetailBid extends Component {
    render() {
        return <div id="front" className="front panel-top scroll-section1 news-item">
            <div className="container">
                <div className="region region-front">
                    <section id="block-views-tin-tuc-block-2" className="block block-views clearfix">
                        <h2 className="block-title"><span>Thông tin đấu giá</span></h2>
                        <div className="block-content">
                            <div className="view view-tin-tuc view-id-tin_tuc view-display-id-block_2 row">
                                <div className="view-content  col-xs-12 col-md-6">
                                    <div className="views-row views-row-1">
                                        <article itemScope="" itemType="http://schema.org/NewsArticle"
                                                 className="node node-tin-tuc node-teaser view-mode-teaser padding-bottom margin-bottom well well-sm">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <Link
                                                        to={domain + "/tin-tuc/" + currentBid.id}>

                                                        <span
                                                            itemProp="image" itemScope=""
                                                            itemType="http://schema.org/ImageObject">
                                                            <img
                                                                className="img-responsive"
                                                                src={currentBid.image}></img>
                                                    </span></Link>
                                                    <div className="field field-name-title">
                                                        <h3 className="h3">
                                                            <Link className="field-name-title"
                                                                  to={domain + "/tin-tuc/" + currentBid.id}>{currentBid.title}</Link>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div
                                                        className="field field-name-post-date text-muted">{currentBid.date}</div>
                                                </div>
                                                <div className="col-sm-6 text-right">
                                                    <Link
                                                        to={domain + "/tin-tuc/" + currentBid.id}
                                                        className="strong field-name-title">Chi tiết</Link>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                </div>

                                <div className="attachment attachment-after col-xs-12 col-md-6">
                                    <div className="view view-tin-tuc view-id-tin_tuc view-display-id-attachment_1">
                                        <div className="view-content">
                                            <div id="views-bootstrap-media-1"
                                                 className="views-bootstrap-media-plugin-style">
                                                <ul className="media-list">
                                                    {
                                                        newsIDList.map((item, index) => {
                                                            const news = newsData[item];
                                                            console.log(news);
                                                            return <NewMediaItem key={"new_media_item" + index}
                                                                                 to={domain + "/tin-tuc/" + news.id}
                                                                                 image={news.image}
                                                                                 title={news.title}
                                                                                 date={news.date}
                                                            ></NewMediaItem>
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>;
    }
}

export function NewMediaItem(props) {
    return <li className="media">
        <div className="pull-left">
            <Link className="field-name-title"
                  to={props.to}><span
                itemProp="image" itemScope=""
                itemType="http://schema.org/ImageObject">
                <img
                    className="img-thumbnail media-object img-responsive"
                    src={props.image}
                    width="70" height="70"></img>
                </span></Link>
        </div>

        <div className="media-body">
            <h4 className="media-heading">
                <Link className="field-name-title"
                      to={props.to}>{props.title}</Link>
            </h4>

            <div
                className="field field-name-post-date text-muted small">
                {props.date}
            </div>
        </div>
    </li>
}