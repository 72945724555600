import React, {useState} from 'react';
import './ImageSlider.css';

function ImageSlider(props) {
    // if (props.imgs == null) {
    //     return <div></div>
    // }
    const imgs = props.imgs;
    const [wordData, setWordData] = useState({
        id: imgs[0].id,
        image: imgs[0].image,
    })
    const handleClick = (index) => {
        console.log(index)
        const wordSlider = imgs[index];
        setWordData(wordSlider)
    }
    return (
        <div className="image_slider">
            <img className={"image_show"} src={wordData.image}/>
            <div className='flex_row'>
                {imgs.map((data, i) =>
                    <div className="image_slider_thumbnail" key={i}>
                        <img className={wordData.id === i ? "clicked" : ""} src={data.image}
                             onClick={() => handleClick(i)} height="70" width="100"/>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ImageSlider;