import './App.css';
import {HeaderRawComponent} from "./app/header/HeaderRawComponent";
import {Footer} from "./app/Footer/Footer";
import {Home} from "./app/home/Home";
import {NewsPDFInfo} from "./app/news/NewsPDFInfo";
import {Route, Routes} from "react-router-dom";
import {About} from "./app/about/About";
import {NewsPDFInfo2} from "./app/news/NewsPDFInfo_2";

export const domain = "https://daugiahopdanhmientrung.vn";

function App() {
    return (
        <div className="App">
            <HeaderRawComponent/>
            <Routes>
                <Route path="/" element={<Home/>}></Route>
                <Route path="/tin-tuc/:newsId" element={<NewsPDFInfo2/>}> </Route>
                <Route path="/gioi-thieu" element={<About/>}></Route>
            </Routes>
            <Footer/>
        </div>
    );
}

export default App;
