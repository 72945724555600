import {Component} from "react";

export class Contact extends Component {
    render() {
        return <div id="panel-top-second" className="panel-top panel-top-second scroll-section1">
            <div className="container">
                <div className="region region-panel-top-second">
                    <section id="block-block-16" className="block block-block clearfix">
                        <div className="block-content">
                            <div className="row">
                                <div className="col-lg-12 text-center">
                                    <h2 className="section-heading text-primary "><span className={"h2-default"}>LIÊN HỆ</span></h2>
                                    <h3 className="section-subheading text-muted"></h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-4">
                                    <div className="card hovercard">
                                        <div className="cardheader"></div>
                                        <div className="avatar"> <span className="fa-stack fa-5x"> <i
                                            className="fa fa-circle fa-stack-2x text-primary"></i> <i
                                            className="fa fa-user fa-stack-1x fa-inverse"></i> </span></div>
                                        <div className="info">
                                            <div className="title"><strong><span>BÙI VĂN PHƯƠNG</span></strong></div>
                                            <div className="desc"> Giám đốc, đấu giá viên</div>
                                            <div className="desc"><strong>0233 3565 379</strong></div>
                                            <div className="desc"><a className="a-default"
                                                href="mailto:daugiamientrung@gmail.com">daugiamientrung@gmail.com</a>
                                            </div>
                                        </div>
                                        <div className="bottom"><a className="btn btn-primary btn-twitter btn-sm a-default"
                                                                   href="https://daugiahopdanhmientrung.vn/#"><i
                                            className="fa fa-twitter"></i> </a> <a
                                            className="btn btn-danger btn-sm" href="https://daugiahopdanhmientrung.vn/#"> <i
                                            className="fa fa-google-plus"></i> </a> <a
                                            className="btn btn-primary btn-sm"
                                            href="https://daugiahopdanhmientrung.vn/#"> <i className="fa fa-facebook"></i>
                                        </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-4">
                                    <div className="card hovercard">
                                        <div className="cardheader"></div>
                                        <div className="avatar"><strong><span className="fa-stack fa-5x"> <i
                                            className="fa fa-circle fa-stack-2x text-primary"></i> <i
                                            className="fa fa-user fa-stack-1x fa-inverse"></i> </span></strong>
                                        </div>
                                        <div className="info">
                                            <div className="title"><strong><span>LÊ QUẬN MƯỜI</span></strong></div>
                                            <div className="desc"> Đấu giá viên</div>
                                            <div className="desc"><strong>0233 3565 379</strong></div>
                                            <div className="desc"><a className="a-default"
                                                href="mailto:daugiamientrung@gmail.com">daugiamientrung@gmail.com</a>
                                            </div>
                                        </div>
                                        <div className="bottom"><a className="btn btn-primary btn-twitter btn-sm"
                                                                   href="https://daugiahopdanhmientrung.vn/#"><i
                                            className="fa fa-twitter"></i> </a> <a
                                            className="btn btn-danger btn-sm" href="https://daugiahopdanhmientrung.vn/#"> <i
                                            className="fa fa-google-plus"></i> </a> <a
                                            className="btn btn-primary btn-sm"
                                            href="https://daugiahopdanhmientrung.vn/#"> <i className="fa fa-facebook"></i>
                                        </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-4">
                                    <div className="card hovercard">
                                        <div className="cardheader"></div>
                                        <div className="avatar"> <span className="fa-stack fa-5x"> <i
                                            className="fa fa-circle fa-stack-2x text-primary"></i> <i
                                            className="fa fa-user fa-stack-1x fa-inverse"></i> </span></div>
                                        <div className="info">
                                            <div className="title"><strong><span>HOÀNG MINH TÚ</span></strong></div>
                                            <div className="desc"> Nhân viên</div>
                                            <div className="desc"><strong>0233 3565 379</strong></div>
                                            <div className="desc"><a className="a-default"
                                                href="mailto:daugiamientrung@gmail.com">daugiamientrung@gmail.com</a>
                                            </div>
                                        </div>
                                        <div className="bottom"><a className="btn btn-primary btn-twitter btn-sm"
                                                                   href="https://daugiahopdanhmientrung.vn/#"><i
                                            className="fa fa-twitter"></i> </a> <a
                                            className="btn btn-danger btn-sm" href="https://daugiahopdanhmientrung.vn/#"> <i
                                            className="fa fa-google-plus"></i> </a> <a
                                            className="btn btn-primary btn-sm"
                                            href="https://daugiahopdanhmientrung.vn/#"> <i className="fa fa-facebook"></i>
                                        </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>;
    }
}