import React, {useState} from "react";
import {Document, Page} from 'react-pdf'
import {newsData, notFoundNews} from "./NewsData";
import {useParams} from "react-router-dom";
import {HotLine} from "../hotline/HotLine";
import ImageSlider from "./ImageSlider";

export function NewsPDFInfo2() {
    const {newsId} = useParams()
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const result = newsData[newsId] != null ? newsData[newsId] : notFoundNews;
    console.log("newsId: ", newsId)
    console.log("result: ", result)

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    return (<div>
            <div className="container">
                <div className="row">
                    <div className="col-md-9">
                        <div id="main-wrapper" className="clearfix">
                            <a id="main-content"></a>
                            <h1 className="page-header">{result.title}</h1>
                            <div className="region region-content">
                                {result.pdfFile ? <center>
                                    <div>
                                        {result.images ? <ImageSlider imgs={result.images}></ImageSlider>: <div></div>}

                                        <Document file={result.pdfFile} onLoadSuccess={onDocumentLoadSuccess}
                                                  onLoadError={console.error}>
                                            {Array.from(new Array(numPages), (el, index) => (<Page
                                                    key={`page_${index + 1}`}
                                                    pageNumber={index + 1}
                                                    renderAnnotationLayer={false}
                                                    scale={1.5}
                                                />))}
                                        </Document>
                                    </div>
                                </center> : <div></div>}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <HotLine/>
                    </div>
                </div>
            </div>
        </div>)
}