import {Component} from "react";

import Carousel from 'react-bootstrap/Carousel';


export class SlideShow extends Component {
    render() {
        return (<div id="slideshow" className="slideshow">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <Carousel>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={"/assets/images/slide/slide_1.png"}
                                        alt="First slide"
                                    />
                                    <Carousel.Caption>
                                        {/*<h1>CÔNG TY ĐẤU GIÁ HỢP DANH MIỀN TRUNG</h1>*/}
                                        {/*<p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>*/}
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={"/assets/images/slide/slide_2.png"}
                                        alt="Second slide"
                                    />

                                    <Carousel.Caption>
                                        {/*<h1>CÔNG TY ĐẤU GIÁ HỢP DANH MIỀN TRUNG</h1>*/}
                                        {/*<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={"/assets/images/slide/slide_3.png"}
                                        alt="Third slide"
                                    />

                                    <Carousel.Caption>
                                        {/*<h1>CÔNG TY ĐẤU GIÁ HỢP DANH MIỀN TRUNG</h1>*/}
                                        {/*<p> Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>*/}
                                    </Carousel.Caption>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}