export const newsData = {
    "tb_09_2023": {
        id: "tb_09_2023",
        title: "Thông báo đấu giá số 09/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_09_2023.pdf",
        image: "/assets/images/TBDG/tb_09_2023_1.png",
        date: "14-04-2023",

    },
    "tb_08_2023": {
        id: "tb_08_2023",
        title: "Thông báo đấu giá số 08/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_08_2023_1.pdf",
        image: "/assets/images/TBDG/tb_08_2023_1.png",
        date: "17-04-2023",
    },
    "TB_15_2023_0004": {
        id: "TB_15_2023_0004",
        title: "Thông báo đấu giá số 15/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_15_2023_0004.pdf",
        image: "/assets/images/TBDG/TB_15_2023_0004.png",
        date: "27-04-2023",
    },
    "TB_23_2023_0001": {
        id: "TB_23_2023_0001",
        title: "Thông báo đấu giá số 23/TBĐG-CTMT",
        pdfFile: "/assets/TBDG/DAU_GIA_TINH_05_2023/TB_23_2023_0001.pdf",
        image: "/assets/TBDG/DAU_GIA_TINH_05_2023/SODO_DAU_GIA.jpg",
        date: "13-05-2023",
        images: [
            {
                id: "SODO_DAU_GIA",
                image: "/assets/TBDG/DAU_GIA_TINH_05_2023/SODO_DAU_GIA.jpg",
            },
            {
                id: "KDT_NAM_DONG_HA_GIAI_DOAN_2.jpg",
                image: "/assets/TBDG/DAU_GIA_TINH_05_2023/KDT_NAM_DONG_HA_GIAI_DOAN_2.jpg",
            },
            {
                id: "KV_K20.jpg",
                image: "/assets/TBDG/DAU_GIA_TINH_05_2023/KV_K20.jpg",
            },
            {
                id: "KV_ODT_16_17_1.jpg",
                image: "/assets/TBDG/DAU_GIA_TINH_05_2023/KV_ODT_16_17_1.jpg",
            },
            //
            {
                id: "KDT_NAM_DONG_HA_GIAI_DOAN_2_LO.png",
                image: "/assets/TBDG/DAU_GIA_TINH_05_2023/13_05_2023/KDT_NAM_DONG_HA_GIAI_DOAN_2_LO.png",
            },
            {
                id: "KV_ODT_16_17_1_LO.png",
                image: "/assets/TBDG/DAU_GIA_TINH_05_2023/13_05_2023/KV_ODT_16_17_1_LO.png",
            },
        ]
    },
    "TB_26_2023": {
        id: "TB_26_2023",
        title: "Thông báo đấu giá số 26/TBĐG-CTMT",
        pdfFile: "/assets/TBDG/DAU_GIA_TINH_05_2023/TB_26_2023.pdf",
        image: "/assets/TBDG/DAU_GIA_TINH_05_2023/SODO_DAU_GIA.jpg",
        date: "20-05-2023",
        images: [
            {
                id: "SODO_DAU_GIA",
                image: "/assets/TBDG/DAU_GIA_TINH_05_2023/SODO_DAU_GIA.jpg",
            },
            {
                id: "KDT_NAM_DONG_HA_GIAI_DOAN_2.jpg",
                image: "/assets/TBDG/DAU_GIA_TINH_05_2023/KDT_NAM_DONG_HA_GIAI_DOAN_2.jpg",
            },
            {
                id: "KDT_NAM_DONG_HA_GIAI_DOAN_3.jpg",
                image: "/assets/TBDG/DAU_GIA_TINH_05_2023/KDT_NAM_DONG_HA_GIAI_DOAN_3.jpg",
            },
            {
                id: "KV_K20.jpg",
                image: "/assets/TBDG/DAU_GIA_TINH_05_2023/KV_K20.jpg",
            },
            {
                id: "KV_ODT_16_17_1.jpg",
                image: "/assets/TBDG/DAU_GIA_TINH_05_2023/KV_ODT_16_17_1.jpg",
            },
            {
                id: "KV_ODT_16_17_1.jpg",
                image: "/assets/TBDG/DAU_GIA_TINH_05_2023/KV_ODT_16_17_2.jpg",
            },
            //
            {
                id: "KV_K20_LO.png",
                image: "/assets/TBDG/DAU_GIA_TINH_05_2023/20_05_2023/KV_K20_LO.png",
            },
            {
                id: "KV_ODT_16_17_1_LO.png",
                image: "/assets/TBDG/DAU_GIA_TINH_05_2023/20_05_2023/KV_ODT_16_17_1_LO.png",
            },
            {
                id: "KV_ODT_16_17_2_LO.png",
                image: "/assets/TBDG/DAU_GIA_TINH_05_2023/20_05_2023/KV_ODT_16_17_2_LO.png",
            }
        ]
    },
    "TB_37_PL_0001": {
        id: "TB_37_PL_0001",
        title: "Thông báo đấu giá số 37/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_37_PL_0001.pdf",
        image: "/assets/images/TBDG/tb_37_pl_0001.png",
        date: "10-06-2023",
    },
    "TB_59_2023": {
        id: "TB_59_2023",
        title: "Thông báo đấu giá số 59/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_59.2023_VA_PL_GIA.pdf",
        image: "/assets/images/TBDG/TB_59.jpg",
        date: "24-06-2023",
    },
    "TB_64_2023": {
        id: "TB_64_2023",
        title: "Thông báo đấu giá số 64/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_64.2023_0001.pdf",
        image: "/assets/images/TBDG/TB_64_2023.jpg",
        date: "23-06-2023",
    },
    "TB_66_2023": {
        id: "TB_66_2023",
        title: "Thông báo đấu giá số 66/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_66.20236_0001.pdf",
        image: "/assets/images/TBDG/TB_66_2023.jpg",
        date: "23-06-2023",
    },
    "TB_88_2023": {
        id: "TB_88_2023",
        title: "Thông báo đấu giá số 88/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_88.2023.pdf",
        image: "/assets/images/TBDG/TB_88.2023.jpg",
        date: "09-07-2023",
    },
    "TB_91_2023": {
        id: "TB_91_2023",
        title: "Thông báo đấu giá số 91/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_91.2023.pdf",
        image: "/assets/images/TBDG/TB_91.2023.jpg",
        date: "09-07-2023",
    },
    "TB_94_2023": {
        id: "TB_94_2023",
        title: "Thông báo đấu giá số 94/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_94.2023.pdf",
        image: "/assets/images/TBDG/TB_94.2023.jpg",
        date: "10-07-2023",
    },
    "TB_125_2023": {
        id: "TB_125_2023",
        title: "Thông báo đấu giá số 125/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_125_2023.pdf",
        image: "/assets/images/TBDG/TB_125_2023.png",
        date: "11-08-2023",
    },
    "TB_QC_TAU_THADS_TP_0001": {
        id: "TB_QC_TAU_THADS_TP_0001",
        title: "Thông báo đấu giá số 130/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_QC_TAU_THADS_TP_0001.pdf",
        image: "/assets/images/TBDG/TB_QC_TAU_THADS_TP_0001.jpg",
        date: "14-08-2023",
    },
    "TB_QC_THA_DONG_HA_CHI_LY_0001": {
        id: "TB_QC_THA_DONG_HA_CHI_LY_0001",
        title: "Thông báo đấu giá số 125/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_QC_THA_DONG_HA_CHI_LY_0001.pdf",
        image: "/assets/images/TBDG/TB_QC_THA_DONG_HA_CHI_LY_0001.jpg",
        date: "11-08-2023",
    },
    "TB_QC_THA_THI_XA_0001": {
        id: "TB_QC_THA_THI_XA_0001",
        title: "Thông báo đấu giá số 127/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_QC_THA_THI_XA_0001.pdf",
        image: "/assets/images/TBDG/TB_QC_THA_THI_XA_0001.jpg",
        date: "11-08-2023",
    },
    "TB_QC_VIETINBNAK_0001": {
        id: "TB_QC_VIETINBNAK_0001",
        title: "Thông báo đấu giá số 116/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_QC_VIETINBNAK_0001.pdf",
        image: "/assets/images/TBDG/TB_QC_VIETINBNAK_0001.jpg",
        date: "03-08-2023",
    },
    "TB_QC_XE_THA_TP_DH_0001": {
        id: "TB_QC_XE_THA_TP_DH_0001",
        title: "Thông báo đấu giá số 114/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_QC_XE_THA_TP_DH_0001.pdf",
        image: "/assets/images/TBDG/TB_QC_XE_THA_TP_DH_0001.jpg",
        date: "28-07-2023",
    },
    "TB_145_2023": {
        id: "TB_145_2023",
        title: "Thông báo đấu giá số 145/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_145.2023_146.2023_0001.pdf",
        image: "/assets/images/TBDG/TB_145_2023.jpg",
        date: "26-08-2023",
    },

    "TB_157_2023": {
        id: "TB_157_2023",
        title: "Thông báo đấu giá số 157/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_157.2023_158.2023_0001.pdf",
        image: "/assets/images/TBDG/TB_157_2023.jpg",
        date: "28-08-2023",
    },
    "TB_148_2023": {
        id: "TB_148_2023",
        title: "Thông báo đấu giá số 148/TBĐG-CTMT",
        pdfFile: "/assets/pdf/XE_TB_148.2023_149.2023_0001.pdf",
        image: "/assets/images/TBDG/TB_148_2023.jpg",
        date: "18-08-2023",
    },
    "TBDG_53_22022024": {
        id: "TBDG_53_22022024",
        title: "Thông báo đấu giá số 53/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TBDG_53_2024.pdf",
        image: "/assets/images/TBDG/TBDG_53_22022024.jpg",
        date: "13-03-2024",
    },
    "TBDB_52_22022024": {
        id: "TBDB_52_22022024",
        title: "Thông báo đấu giá số 52/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TBDB_52_2024_36.pdf",
        image: "/assets/images/TBDG/TBDB_52_22022024.jpg",
        date: "16-03-2024",
    },
    "TBDG_31_STNMT_0001": {
        id:"TBDG_31_STNMT_0001",
        title:"Thông báo đấu giá số 31/TBĐG-CTMT",
        image: "/assets/images/TBDG/TBDG_31_STNMT_0001.jpg",
        pdfFile:"/assets/pdf/TBDG_31_STNMT_0001.pdf",
        date: "30-01-2024"
    },
    "TBDG_82_0001": {
        id:"TBDG_82_0001",
        title:"Thông báo đấu giá số 82/TBĐG-CTMT",
        image: "/assets/images/TBDG/TBDG_82_0001.jpg",
        pdfFile:"/assets/pdf/TBDG_82_0001.pdf",
        date: "08-04-2024"
    },
    "TBDG_84_0001": {
        id:"TBDG_84_0001",
        title:"Thông báo đấu giá số 84/TBĐG-CTMT",
        image: "/assets/images/TBDG/TBDG_84_0001.jpg",
        pdfFile:"/assets/pdf/TBDG_84_0001.pdf",
        date: "08-04-2024"
    },
    "TBDG_95_TAN_LONG_0001": {
        id:"TBDG_95_TAN_LONG_0001",
        title:"Thông báo đấu giá số 95/TBĐG-CTMT",
        image: "/assets/images/TBDG/TBDG_95_TAN_LONG_0001.jpg",
        pdfFile:"/assets/pdf/TBDG_95_TAN_LONG_0001.pdf",
        date: "15-04-2024"
    },
    "TBDG_93_0001": {
        id:"TBDG_93_0001",
        title:"Thông báo đấu giá số 93/TBĐG-CTMT",
        image: "/assets/images/TBDG/TBDG_93_0001.jpg",
        pdfFile:"/assets/pdf/TBDG_93_0001.pdf",
        date: "15-04-2024"
    },
    "TBDG_89_2024": {
        id:"TBDG_89_2024",
        title:"Thông báo đấu giá số 89/TBĐG-CTMT",
        image: "/assets/images/TBDG/TBDG_89_2024.jpg",
        pdfFile:"/assets/pdf/TBDG_89_2024.pdf",
        date: "16-04-2024"
    },
    "TBDG_97_0001": {
        id:"TBDG_97_0001",
        title:"Thông báo đấu giá số 97/TBĐG-CTMT",
        image: "/assets/images/TBDG/TBDG_97_0001.jpg",
        pdfFile:"/assets/pdf/TBDG_97_0001.pdf",
        date: "16-04-2024"
    },
    "TBDG_115_0001": {
        id:"TBDG_115_0001",
        title:"Thông báo đấu giá số 115/TBĐG-CTMT",
        image: "/assets/images/TBDG/TBDG_115_0001.jpg",
        pdfFile:"/assets/pdf/TBDG_115_0001.pdf",
        date: "16-04-2024"
    },
    "TBDG_101_0001": {
        id:"TBDG_101_0001",
        title:"Thông báo đấu giá số 101/TBĐG-CTMT",
        image: "/assets/images/TBDG/TBDG_101_0001.jpg",
        pdfFile:"/assets/pdf/TBDG_101_0001.pdf",
        date: "17-04-2024"
    },
    "TBDG_10808_0001": {
        id:"TBDG_10808_0001",
        title:"Thông báo đấu giá số 108/TBĐG-CTMT",
        image: "/assets/images/TBDG/TBDG_10808_0001.jpg",
        pdfFile:"/assets/pdf/TBDG_10808_0001.pdf",
        date: "21-04-2024"
    },
    "TBDG_117": {
        id:"TBDG_117",
        title:"Thông báo đấu giá số 117/TBĐG-CTMT",
        image: "/assets/images/TBDG/TBDG_117.jpg",
        pdfFile:"/assets/pdf/TBDG_117.pdf",
        date: "22-04-2024"
    },
    "TBDG_119_0001": {
        id:"TBDG_119_0001",
        title:"Thông báo đấu giá số 119/TBĐG-CTMT",
        image: "/assets/images/TBDG/TBDG_119_0001.jpg",
        pdfFile:"/assets/pdf/TBDG_119_0001.pdf",
        date: "22-04-2024"
    },
    "TBDG_122_0001": {
        id:"TBDG_122_0001",
        title:"Thông báo đấu giá số 122/TBĐG-CTMT",
        image: "/assets/images/TBDG/TBDG_122_0001.jpg",
        pdfFile:"/assets/pdf/TBDG_122_0001.pdf",
        date: "24-04-2024"
    }

}

export const newsIDList = [
    "TBDG_122_0001",
    "TBDG_119_0001",
    "TBDG_117",
    "TBDG_10808_0001",
    "TBDG_101_0001",
    "TBDG_115_0001",
    "TBDG_97_0001",
    "TBDG_89_2024",
    "TBDG_93_0001",
    "TBDG_95_TAN_LONG_0001",
    "TBDG_84_0001",
    "TBDG_82_0001",
    "TBDG_31_STNMT_0001",
    "TBDB_52_22022024",
    "TBDG_53_22022024",
    "TB_148_2023",
    "TB_145_2023",
    "TB_157_2023",
    "TB_QC_VIETINBNAK_0001",
    "TB_QC_THA_DONG_HA_CHI_LY_0001",
    "TB_125_2023",
    "TB_QC_THA_THI_XA_0001",
    "TB_QC_TAU_THADS_TP_0001",
    "TB_QC_XE_THA_TP_DH_0001",
    "TB_88_2023",
    "TB_91_2023",
    "TB_94_2023",
    "TB_66_2023",
    "TB_64_2023",
    "TB_59_2023",
    "TB_26_2023",
    "TB_37_PL_0001"
];
// "TB_15_2023_0004", "tb_08_2023", "tb_09_2023", "TB_23_2023_0001"
export const currentBid = newsData.TBDG_122_0001;

export const notFoundNews = {
    title: "Không tìm thấy thông báo",
    pdfFile: "",
    image: "",
};