import React, {useState} from "react";
import {Link} from "react-router-dom";


export function HeaderRawComponent() {
    const [showService, setShowService] = useState("");
    const [showFormalities, setShowFormalities] = useState("");
    const showServiceDropdown = (e) => {
        setShowService(" open");
    }
    const hideServiceDropdown = e => {
        setShowService("");
    }
    const showFormalitiesDropdown = (e) => {
        setShowFormalities(" open");
    }
    const hideFormalitiesDropdown = e => {
        setShowFormalities("");
    }


    return (<div>
            <nav id="navbar" role="banner" className="navbar navbar-fixed-top navbar-default" itemScope=""
                 itemType="https://schema.org/WPHeader">
                <div className="container row">
                    <div className="navbar-header col-md-3 col-lg-2">
                        <a className="logo pull-left" href="http://daugiahopdanhmientrung.vn/" title="Trang chủ">
                            <img
                                src={"/assets/images/logo.png"}
                                alt="Trang chủ"></img>
                        </a>
                        <button type="button" className="navbar-toggle navbar-toggler" data-bs-toggle="collapse"
                                data-bs-target="#navbarCollapse" aria-controls="navbarCollapse"
                                aria-label="Toggle navigation">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                    </div>
                    <div className="navbar-collapse collapse col-md-10 col-lg-10" id="navbarCollapse">
                            <ul className="menu nav navbar-right navbar-nav navbar-nav-custom"
                                data-smartmenus-id="1679124626324421">
                                <li className="first leaf active active"><a
                                    href="https://daugiahopdanhmientrung.vn/"
                                    title=""
                                    className="active">Trang chủ</a>
                                </li>
                                <li className="leaf"><Link
                                    to="https://daugiahopdanhmientrung.vn/gioi-thieu">Giới thiệu</Link>
                                </li>
                                <li className={"expanded dropdown" + showService}>
                                    <a href="https://daugiahopdanhmientrung.vn/dich-vu"
                                       className="dropdown-toggle"
                                       onMouseEnter={showServiceDropdown}
                                       onMouseLeave={hideServiceDropdown}
                                    >Dịch vụ</a>
                                    <ul className="dropdown-menu"
                                        style={{
                                            display: showService === "" ? "none" : "block",

                                        }}
                                        onMouseEnter={showServiceDropdown}
                                        onMouseLeave={hideServiceDropdown}
                                    >
                                        <li className=""><a
                                            href="https://daugiahopdanhmientrung.vn/dich-vu/chuc-dau-gia-bat-dong-san-dong-san">Tổ
                                            chức đấu giá bất động sản, động sản</a></li>
                                        <li className=""><a
                                            href="https://daugiahopdanhmientrung.vn/dich-vu/chuc-dau-gia-tai-san-tang-vat-vi-pham-hanh-chinh">Tổ
                                            chức đấu giá tài sản tang vật, vi phạm hành chính</a></li>
                                        <li className=""><a
                                            href="https://daugiahopdanhmientrung.vn/dich-vu/chuc-dau-gia-tai-san-thi-hanh-tai-san-dam-bao">Tổ
                                            chức đấu giá tài sản thi hành án, tài sản đảm bảo</a></li>
                                        <li className=""><a
                                            href="https://daugiahopdanhmientrung.vn/dich-vu/chuc-dau-gia-tai-san-thuoc-so-huu-ca-nhan-chuc">Tổ
                                            chức đấu giá tài sản thuộc sở hữu cá nhân, tổ chức</a></li>
                                    </ul>

                                </li>
                                <li className={"expanded dropdown" + showFormalities}><a
                                    href="https://daugiahopdanhmientrung.vn/thu-tuc"
                                    className="dropdown-toggle has-submenu"
                                    onMouseEnter={showFormalitiesDropdown}
                                    onMouseLeave={hideFormalitiesDropdown}
                                >Thủ tục</a>
                                    <ul className="dropdown-menu" id="sm-1679124626324421-4" role="group"
                                        aria-hidden="true"
                                        aria-labelledby="sm-1679124626324421-3" aria-expanded="false"
                                        onMouseEnter={showFormalitiesDropdown}
                                        onMouseLeave={hideFormalitiesDropdown}
                                        style={{
                                            display: showFormalities === "" ? "none" : "block",
                                            top: "auto",
                                            left: " 0px",
                                            marginLeft: "",
                                            marginTop: " 0px",
                                            minWidth: "10em",
                                            maxWidth: "20em",
                                        }}
                                    >
                                        <li className="first leaf"><a
                                            href="https://daugiahopdanhmientrung.vn/van-ban/noi-quy">Nội
                                            quy</a></li>
                                        <li className="last leaf"><a
                                            href="https://daugiahopdanhmientrung.vn/van-ban/quy-che">Quy
                                            chế</a></li>
                                    </ul>
                                </li>
                                <li className="leaf"><a
                                    href="https://daugiahopdanhmientrung.vn/van-ban-phap-luat">Văn
                                    bản pháp
                                    luật</a></li>
                                <li className="leaf"><a
                                    href="https://daugiahopdanhmientrung.vn/thong-tin-dau-gia"
                                    title="Thông tin đấu giá">Thông tin đấu giá</a></li>
                                <li className="leaf"><a
                                    href="https://daugiahopdanhmientrung.vn/trang/tuyen-dung">Tuyển
                                    dụng</a>
                                </li>
                                <li className="last leaf"><a href="https://daugiahopdanhmientrung.vn/lien-he">Liên
                                    hệ</a></li>
                            </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}