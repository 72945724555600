import {Component} from "react";

export class Footer extends Component {
    render() {
        return <div className="section-footer1 scroll-section1">
            <meta itemProp="name description" content="Footer"></meta>
                <div id="footer">
                    <div className="container">
                        <div className="row">
                            <div className="footer-block col-xs-12 col-md-8 text-center1">
                                <div className="region region-footer-first">
                                    <section id="block-nodesinblock-0" className="block block-nodesinblock clearfix">

                                        <div className="block-content">
                                            <div className="nodesinblock first last odd" id="nodesinblock-29">

                                                <div className="node node-chi-nhanh view-mode-grid_view ">
                                                    <div className="row">
                                                        <div className="col-sm-12 ">
                                                            <h2 style={{textAlign:"left"}}>CÔNG TY ĐẤU GIÁ HỢP DANH MIỀN TRUNG</h2>
                                                            <div
                                                                className="field field-name-field-cn-add field-type-addressfield field-label-hidden">
                                                                <i className="fa fa-map-marker fa-fw"
                                                                   aria-hidden="true"></i><span>Đường Trần Nhật Duật, Thành Phố Đông Hà, Tỉnh Quảng Trị</span>
                                                            </div>
                                                            <div className="field field-name-field-cn-tel field-type-text field-label-hidden">
                                                                <i className="fa fa-phone fa-fw"
                                                                   aria-hidden="true"></i><span>(0233) 3565.379</span>
                                                            </div>
                                                            <div className="field field-name-field-cn-tel field-type-text field-label-hidden">
                                                                <i className="fa fa-fax fa-fw"
                                                                   aria-hidden="true"></i><span>Fax:(0233) 3565.379</span>
                                                            </div>
                                                            <div
                                                                className="field field-name-field-cn-email field-type-text field-label-hidden">
                                                                <i className="fa fa-envelope fa-fw"
                                                                   aria-hidden="true"></i><span>daugiamientrung@gmail.com</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                            <div className="footer-block col-xs-12 col-md-4">
                                <div className="region region-footer-second">
                                    <section id="block-views-lien-he-block-1" className="block block-views clearfix">

                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <footer className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="region region-footer">
                                <nav id="block-menu-block-1"
                                     className="block block-menu-block margin-bottom text-center">


                                    <div
                                        className="menu-block-wrapper menu-block-1 menu-name-menu-footer-links parent-mlid-0 menu-level-1">
                                        <ul className="menu menu list-inline">
                                            <li className="first leaf menu-mlid-1729 active active"><a
                                                href="https://daugiahopdanhmientrung.vn/" title="" className="active">Trang
                                                chủ</a>
                                            </li>
                                            <li className="leaf menu-mlid-1692"><a
                                                href="https://daugiahopdanhmientrung.vn/trang/gioi-thieu">Giới thiệu</a>
                                            </li>
                                            <li className="leaf menu-mlid-1724"><a
                                                href="https://daugiahopdanhmientrung.vn/dich-vu"
                                                title="">Dịch vụ</a>
                                            </li>
                                            <li className="leaf menu-mlid-1725"><a
                                                href="https://daugiahopdanhmientrung.vn/thu-tuc"
                                                title="">Thủ tục</a>
                                            </li>
                                            <li className="leaf menu-mlid-1726"><a
                                                href="https://daugiahopdanhmientrung.vn/van-ban-phap-luat" title="">Văn
                                                bản pháp luật</a></li>
                                            <li className="leaf menu-mlid-1728"><a
                                                href="https://daugiahopdanhmientrung.vn/trang/tuyen-dung" title="">Tuyển
                                                dụng</a></li>
                                            <li className="leaf active-trail active menu-mlid-1177 active"><a
                                                href="https://daugiahopdanhmientrung.vn/lien-he" title=""
                                                className="active-trail">Liên hệ</a></li>
                                            <li className="last leaf menu-mlid-1157"><a
                                                href="https://daugiahopdanhmientrung.vn/user/login?current=lien-he" title="">Đăng
                                                nhập</a></li>
                                        </ul>
                                    </div>

                                </nav>
                                <section id="block-block-6" className="block block-block margin-bottom clearfix">

                                    <div className="block-content">
                                        <div className="col-xs-12 text-center">
                                            <span style={{lineHeight: "55px"}}>Copyright © 2011, Công ty Đấu giá Hợp Danh Miền Trung.</span>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div>
                                <div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
        </div>;
    }
}