import React, {Component} from "react";
import {Document, Page} from "react-pdf";
import {HotLine} from "../hotline/HotLine";

export class About extends Component {
    render() {
        return <div>
            <div className="container">
                <div className="row">
                    <div className="col-md-9">
                        <div id="main-wrapper" className="clearfix">
                            <div>
                                <a id="main-content"></a>
                                <h1 className="page-header">giới thiệu</h1>
                                <div style={{
                                    marginRight: "0cm",
                                    marginLeft: "0cm",
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    margin: "0cm",
                                    textAlign: "justify",
                                    textIndent: "36.0pt",
                                    lineHeight: "150%"
                                }}>
                                    <p>
    <span style={{fontSize: 19, lineHeight: "150%"}}>
      Công ty Đấu giá Hợp danh Miền Trung &nbsp;xin gửi lời chào trân trọng, lời
      chúc mừng sự hợp tác thành công và phát triển đến Quý khách hàng.
    </span>
                                    </p>
                                    <p>
    <span style={{lineHeight: "150%", color: "black"}}>
      Công ty Đấu giá Hợp danh Miền Trung &nbsp;(
      <em>
        được chuyển đổi từ Công ty Cổ phần Dịch vụ và Bán đấu giá tài sản Miền
        Trung, là Doanh nghiệp hoạt động trong lĩnh vực bán đấu giá tài sản,
        được thành lập theo giấy phép kinh doanh số 3200507473 do Phòng ĐKKD Sở
        kế hoạch và Đầu tư tỉnh Quảng Trị cấp ngày 05/07/2012
      </em>
      ).
    </span>
                                    </p>
                                    <p>
    <span style={{lineHeight: "150%", color: "black"}}>
      &nbsp;Công ty Đấu giá Hợp danh Miền Trung &nbsp;được Sở tư Pháp tỉnh Quảng
      Trị cấp Giấy đăng ký hoạt động số : 01/ TP-ĐKHĐ ngày 09/10/2018, là Doanh
      nghiệp đủ điều kiện hoạt động đấu giá tài sản theo quy định của Luật đấu
      giá tài sản năm 2016.&nbsp;
    </span>
                                    </p>
                                    <p>
    <span style={{lineHeight: "150%", color: "black"}}>
      Công ty chúng &nbsp;tôi thường xuyên tổ chức các cuộc bán đấu giá tài sản
      gồm Bất động sản và Động sản &nbsp;cho các tổ chức Tín dụng, Các Trung tâm
      phát triển quỹ đất, các Sở, ban ngành , các Cục và Chi Cục Thi hành án dân
      sự , các tổ chức, cá nhân… trong và ngoài tỉnh có nhu cầu. Qua những lần
        &nbsp;tổ chức đó, Công ty chúng tôi không ngừng hoàn thiện về quy trình
      cũng như tính chuyên nghiệp trong việc tổ chức bán đấu giá tài sản.
    </span>
                                    </p>
                                    <p>
    <span style={{fontSize: 19, lineHeight: "150%"}}>
      Với đội ngũ đấu giá viên có trình độ chuyên môn và kinh nghiệm trong lĩnh
      vực đấu giá tài sản, trong lĩnh vực tư vấn pháp luật, Công ty đã và đang
      thực hiện tổ chức thành công các cuộc đấu giá, cũng như tư vấn miễn phí
      các thủ tục pháp lý liên quan đến tài sản và chuyển quyền sở hữu đối với
      tài sản bán đấu giá.&nbsp;
    </span>
                                    </p>
                                    <p>
                                        <em>
      <span style={{lineHeight: "150%"}}>
        ''Chuyên nghiệp - Uy tín - Công khai - Minh bạch ''
      </span>
                                        </em>
                                        <span style={{lineHeight: "150%"}}>
      &nbsp;là phương châm làm việc của Công ty chúng tôi, đây chính là tính
      cách, năng lực, kinh nghiệm, hiệu quả, trách nhiệm và tinh thần hợp tác
      của Công ty đối với khách hàng.
    </span>
                                    </p>
                                    <p>
    <span style={{fontSize: 19, lineHeight: "150%"}}>
      Công ty Đấu giá Hợp danh Miền Trung cam kết thực hiện đúng quy trình tổ
      chức bán đấu giá theo quy định pháp luật . Đảm bảo sự công khai, minh bạch
      , hiệu quả tốt nhất cho khách hàng.&nbsp;
    </span>
                                    </p>
                                    <p>
    <span style={{fontSize: 19, lineHeight: "150%"}}>
      Trân trọng cảm ơn và kính chào hợp tác!
    </span>
                                    </p>
                                </div>
                                <div style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    textAlign: "left",
                                }}>
                                    <p>
                                    </p>
                                    <p>
                                        <strong>I. &nbsp; HỒ SƠ PHÁP LÝ</strong>
                                    </p>
                                    <p>
                                        <strong>1. Thông tin pháp lý:</strong>
                                    </p>
                                    <p >
                                        - Giấy Đăng ký hoạt động của Doanh nghiệp Đấu giá tài sản số 01/TP-ĐKHD ngày
                                        09/10/2018 do Sở Tư pháp tỉnh Quảng Trị cấp.
                                    </p>
                                    <p >
                                        - Giấy Chứng nhận đăng ký doanh nghiệp số 3200507473 ngày 05/07/2012 do
                                        Phòng ĐKKD Sở kế hoạch và Đầu tư tỉnh Quảng Trị cấp.
                                    </p>
                                    <p >
                                        - Chứng chỉ hành nghề Đấu giá do Bộ Tư pháp cấp.
                                    </p>
                                    <p >
                                        - Chứng chỉ đào tạo nghiệp vụ Thẩm định giá do Bộ Tài chính cấp.
                                    </p>
                                    <p >
                                        - Chứng chỉ hành nghề Quản tài viên do Bộ Tư pháp cấp.
                                    </p>
                                    <p >
                                        <strong>1.1. Trụ sở chính&nbsp;:</strong>
                                    </p>
                                    <p >
    <span style={{fontSize: 19}}>
      - Tên tiếng Việt: &nbsp; &nbsp;
        <strong>CÔNG TY ĐẤU GIÁ HỢP DANH MIỀN TRUNG</strong>
    </span>
                                    </p>
                                    <p >
    <span style={{fontSize: 19}}>
      - Tên viết tắt: &nbsp; &nbsp; &nbsp; &nbsp;
        <strong>CÔNG TY ĐẤU GIÁ MIỀN TRUNG</strong>
    </span>
                                    </p>
                                    <p >
    <span style={{fontSize: 19}}>
      - Tên tiếng Anh: &nbsp; &nbsp;
        <strong>MIEN TRUNG AUCTION PARTNERSHIPS</strong>
    </span>
                                    </p>
                                    <p >
    <span style={{fontSize: 19}}>
      - Địa chỉ: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <strong>Đường</strong>{" "}
        <strong>Trần Nhật Duật, tp Đông Hà, tỉnh Quảng Trị</strong>
    </span>
                                    </p>
                                    <p >
    <span style={{fontSize: 19}}>
      - Điện thoại: &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
        <strong>
        (0233) 3565.379 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; Fax: (0233) 3565.379
      </strong>
    </span>
                                    </p>
                                    <p >
                                        - Email: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                                        <strong>
                                            <a href="mailto:daugiamientrung@gmail.com">daugiamientrung@gmail.com</a>
                                        </strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <HotLine/>
                    </div>
                </div>
            </div>
        </div>;
    }
}