import {Component} from "react";

export class CategoriesBanner extends Component {
    render() {
        return <div id="panel-top-first" className="panel-top panel-top-first scroll-section1">
            <div className="container">
                <div className="region region-panel-top-first">
                    <section id="block-block-15" className="block block-block clearfix">

                        <div className="block-content">
                            <div className="row">
                                <div className="col-lg-12 text-center">
                                    <h2 className="section-heading text-primary-custom">
                                        <span>TỔ CHỨC ĐẤU GIÁ CÁC LOẠI TÀI SẢN</span>
                                    </h2>
                                </div>
                            </div>
                            <div className="row text-center">
                                <div className="col-md-3 col-sm-6"><a
                                    href="https://daugiahopdanhmientrung.vn/chuc-dau-gia-bat-dong-san-dong-san"><span
                                    className="fa-stack fa-5x"> <i
                                    className="fa fa-circle fa-stack-2x text-warning"></i> <i
                                    className="fa fa-building-o fa-stack-1x fa-inverse1"></i> </span> </a>
                                    <h3 className="service-heading">
                                        <a className="service-heading" href="https://daugiahopdanhmientrung.vn/chuc-dau-gia-bat-dong-san-dong-san">BẤT ĐỘNG SẢN, ĐỘNG SẢN</a>
                                    </h3>
                                    <p className="text-muted"> Tổ chức đấu giá bất động sản, động sản. </p>
                                </div>
                                <div className="col-md-3 col-sm-6"><a
                                    href="https://daugiahopdanhmientrung.vn/chuc-dau-gia-tai-san-tang-vat-vi-pham-hanh-chinh"
                                    title=""><span className="fa-stack fa-5x"> <i
                                    className="fa fa-circle fa-stack-2x text-warning"></i> <i
                                    className="fa fa-eye fa-stack-1x fa-inverse1"></i> </span> </a>
                                    <h3 className="service-heading"><a className="service-heading"
                                        href="https://daugiahopdanhmientrung.vn/chuc-dau-gia-tai-san-tang-vat-vi-pham-hanh-chinh">TÀI SẢN TANG VẬT, VI PHẠM HÀNH CHÍNH</a></h3>
                                    <p className="text-muted"> Tổ chức đấu giá tài sản tang vật, vi phạm hành
                                        chính. </p>
                                </div>
                                <div className="col-md-3 col-sm-6"><a
                                    href="https://daugiahopdanhmientrung.vn/chuc-dau-gia-tai-san-thi-hanh-tai-san-dam-bao"><span
                                    className="fa-stack fa-5x"> <i
                                    className="fa fa-circle fa-stack-2x text-warning"></i> <i
                                    className="fa fa-balance-scale fa-stack-1x fa-inverse1"></i> </span> </a>
                                    <h3 className="service-heading"><a className="service-heading"
                                        href="https://daugiahopdanhmientrung.vn/chuc-dau-gia-tai-san-thi-hanh-tai-san-dam-bao">TÀI
                                        SẢN THI HÀNH
                                        ÁN, TÀI SẢN ĐẢM BẢO</a></h3>
                                    <p className="text-muted"> Tổ chức đấu giá tài sản thi hành án, tài sản đảm
                                        bảo. </p>
                                </div>
                                <div className="col-md-3 col-sm-6"><a
                                    href="https://daugiahopdanhmientrung.vn/chuc-dau-gia-tai-san-thuoc-so-huu-ca-nhan-chuc"><span
                                    className="fa-stack fa-5x"> <i
                                    className="fa fa-circle fa-stack-2x text-warning"></i> <i
                                    className="fa fa-users fa-stack-1x fa-inverse1"></i> </span> </a>
                                    <h3 className="service-heading"><a className="service-heading"
                                        href="https://daugiahopdanhmientrung.vn/chuc-dau-gia-tai-san-thuoc-so-huu-ca-nhan-chuc">TÀI
                                        SẢN THUỘC SỞ
                                        HỮU CÁ NHÂN, TỔ CHỨC</a></h3>
                                    <p className="text-muted"> Tổ chức đấu giá tài sản thuộc sở hữu cá nhân, tổ
                                        chức. </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>;
    }
}