import React from "react";

export class HotLine extends React.Component {
    render() {
        return <section id="block-block-14" className="well block block-block clearfix">
            <h2 className="block-title">Hotline</h2>
            <div className="block-content">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="media">
                            <div className="media-left media-top">
                                <div className="media-object"><span
                                    className="fa-stack fa-2x"> <i
                                    className="fa fa-circle fa-stack-2x text-primary"></i> <i
                                    className="fa fa-user fa-stack-1x fa-inverse"></i> </span></div>
                            </div>
                            <div className="media-body">
                                <h4 className="media-heading">BÙI VĂN PHƯƠNG</h4>
                                <p><span className="fa fa-phone fa-fw"></span><span>0233 3565 379</span>
                                </p>
                            </div>
                        </div>
                        <div className="media">
                            <div className="media-left media-top">
                                <div className="media-object"><span
                                    className="fa-stack fa-2x"> <i
                                    className="fa fa-circle fa-stack-2x text-primary"></i> <i
                                    className="fa fa-user fa-stack-1x fa-inverse"></i> </span></div>
                            </div>
                            <div className="media-body">
                                <h4>LÊ QUẬN MƯỜI</h4>
                                <p><span className="fa fa-phone fa-fw"></span><span>0233 3565 379</span>
                                </p>
                            </div>
                        </div>
                        <div className="media">
                            <div className="media-left media-top">
                                <div className="media-object"><span
                                    className="fa-stack fa-2x"> <i
                                    className="fa fa-circle fa-stack-2x text-primary"></i> <i
                                    className="fa fa-user fa-stack-1x fa-inverse"></i> </span></div>
                            </div>
                            <div className="media-body">
                                <h4>HOÀNG MINH TÚ</h4>
                                <p><span className="fa fa-phone fa-fw"></span><span>0233 3565 379</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>;
    }
}